
@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
/* @import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css'; */
@import '~@blueprintjs/table/lib/css/table.css';

/** {*/
/*    font-family: Overpass, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;*/
/*    box-sizing: border-box;*/
/*}*/

/*body {*/
/*    padding: 0;*/
/*    margin: 0;*/
/*}*/

/*.app {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

.pano-hotspot {
    height: 24px;
    width: 24px;
    border-radius: 13px;
    background: rgba(96,168,240,0.8);
    border: 2px solid rgba(0, 64, 240, 0.8);
    /*background-position: 0 -156px;*/
    white-space: nowrap;
}

.pnlm-sprite {
    background-repeat: no-repeat;
    background-position: -64px 0;
}

.pano-hotspot:hover {
    background-color: rgba(96, 168, 240, 1);
    border: 2px solid rgba(0, 64, 240, 1);
}
